import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Recommended from "../components/Content/Recommended/recommended"
import logo from "../../static/mondays-text.svg"
import SEO from "../components/seo"

const ThankYouPage = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Thank You" />
      <div className="mt-8 text-center">
        {/* <img width="250px" height="auto" src={logo} alt="Mondays Logo" /> */}
        <h1 className=" mt-0">
          <span>YOUR JOURNEY </span>
          <span className="underline">STARTS HERE</span>
        </h1>
      </div>
      <Recommended />
    </Layout>
  )
}

export default ThankYouPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
