import React from "react"
import cn from "classnames"
import styles from "./card.module.css"

function Card({ children, color }) {
  return (
    <div className={cn("shadow", styles.container, color && styles.color)}>
      {children}
    </div>
  )
}

export default Card
