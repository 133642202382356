import React, { useState, useEffect } from "react"
import FadeInSection from "../../FadeInSection/fadeInSection"
import Card from "../../Card/card"
import cn from "classnames"
import styles from "./recommended.module.css"
import starterLogo from "../../../../static/mondays-pack.svg"
import clubLogo from "../../../../static/mondays-club.svg"
import teamLogo from "../../../../static/mondays-team-plan.svg"
import mainLogo from "../../../../static/mondays-main.svg"
import textLogo from "../../../../static/mondays-text.svg"
import resultsAltLogo from "../../../../static/mondays-results-alt.svg"

function Recommended() {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  const formPref = localStorage.getItem("formPref")

  const plans = [
    {
      name: "PACK",
      id: "starter",
      description:
        "A perfect package if you already have some existing knowledge, but would like to check in with someone once a week",
      image: starterLogo,
    },
    {
      name: "CLUB",
      id: "club",
      description:
        "Our Club package is for those who want extra support in the gym. Meet with a trainer twice a week to ensure you stay on track with your goals.",
      image: clubLogo,
    },
    {
      name: "TEAM",
      id: "team",
      description:
        "You've got big goals and want the team to support you all the way! Benefit from 3 personal training sessions each week - the ultimate support package.",
      image: teamLogo,
    },
    {
      name: "ONLINE",
      id: "online",
      description:
        "You have the experience but want the structure and accountability that our online programme can offer.",
      image: resultsAltLogo,
    },
  ]

  const planList = [
    "Initial consultation",
    "Nonevasive body scan & fitness testing",
    "7-day intro week/food diary",
    "Full nutritional analysis & goal setting",
    "Bespoke fitness programming",
    "Dedicated personal trainer",
    "24/7 support from a qualified nutritionist",
    "Weekly check-ins",
    "6-weekly body progression check-in",
    "Whatsapp group for ongoing support",
    "Integrated mobile app",
  ]

  const recommendedPlan = plans.find(plan => plan.id === formPref)

  if (!formPref || formPref === "none") {
    return (
      <>
        <h4 className={cn("text-center mt-0 mb-0 text-grey")}>
          Thank you - A member of our team will be in touch soon!
        </h4>
        <section>
          <div className="col-100">
            <div className={styles.container}>
              <div className="row">
                <div className={cn(styles.left, "col-50")}>
                  <h3 className="mt-0">ALL OUR PLANS INCLUDE</h3>
                  <ul>
                    {planList.map(item => {
                      return (
                        <li className={styles.listItem} key={item}>
                          {item}
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className={cn(styles.right, "col-50")}>
                  <img
                    id="infinite-m"
                    src={mainLogo}
                    alt="Mondays Infinite M GIF"
                    width="500px"
                    height="auto"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={cn(styles.borderBottom)}></div>
        </section>

        <FadeInSection>
          <h2 className="text-center mb-0 mt-4">
            <span>CHECK OUT OUR RANGE OF </span>
            <span className="underline">BESPOKE</span>
            <span> PLANS</span>
          </h2>
        </FadeInSection>

        <section>
          {plans.map((plan, index) => {
            return (
              <FadeInSection key={plan.name}>
                <div
                  className={cn(
                    index % 2 === 0 ? "row" : "row-reverse",
                    index === 0 ? "mt-0" : "mt-4",
                    styles.rowCenter
                  )}
                >
                  <div className="col-50">
                    <div>
                      <img
                        width="150px"
                        height="auto"
                        src={textLogo}
                        alt="Mondays Logo"
                      />
                      <h2 className="mt-0">
                        <span>MONDAYS </span>
                        <span className="underline">{plan.name}</span>
                      </h2>
                    </div>
                    <p>{plan.description}</p>
                  </div>
                  <div className="col-50">
                    <div>
                      <img src={plan.image} alt="Mondays Plans" />
                    </div>
                  </div>
                </div>
              </FadeInSection>
            )
          })}
        </section>
      </>
    )
  }

  return (
    <>
      <h4 className="text-center mt-0 mb-0 text-grey">
        Thank you - A member of our team will be in touch soon!
      </h4>

      <section>
        <div className="col-100">
          <div className={styles.container}>
            <div className="row">
              <div className={cn(styles.left, "col-50")}>
                <h3 className="mt-0">ALL OUR PLANS INCLUDE</h3>
                <ul>
                  {planList.map(item => {
                    return <li key={item}>◦ {item}</li>
                  })}
                </ul>
                <h4
                  className={cn(
                    "text-grey text-center mt-4 mb-0",
                    styles.textMedium
                  )}
                >
                  See our recommended package below
                </h4>
              </div>
              <div className={cn(styles.right, "col-50")}>
                <img
                  id="infinite-m"
                  src={mainLogo}
                  alt="Mondays Infinite M GIF"
                  width="500px"
                  height="auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={cn(styles.borderBottom)}></div>
      </section>

      <FadeInSection>
        <h2 className="text-center mb-0 mt-4">
          <span>CHECK OUT OUR RANGE OF </span>
          <span className="underline">BESPOKE</span>
          <span> PLANS</span>
        </h2>
      </FadeInSection>

      <section>
        <FadeInSection>
          <Card>
            <div className={styles.cardHeader}>
              <h4 className="text-center mt-0 text-grey">
                Based on your answers, we'd recommend...
              </h4>
            </div>
            <div className={cn("row", styles.rowCenter)}>
              <div className="col-50 text-left">
                <div>
                  <img
                    width="150px"
                    height="auto"
                    src={textLogo}
                    alt="Mondays Logo"
                  />
                  <h2 className="mt-0">
                    <span>MONDAYS </span>
                    <span className="underline">{recommendedPlan.name}</span>
                  </h2>
                </div>
                <p>{recommendedPlan.description}</p>
              </div>
              <div className="col-50">
                <div>
                  <img src={recommendedPlan.image} alt="Mondays Main" />
                </div>
              </div>
            </div>
          </Card>
        </FadeInSection>

        {plans
          .filter(plan => plan.id !== recommendedPlan.id)
          .map((plan, index) => {
            return (
              <FadeInSection key={plan.name}>
                <div
                  className={cn(
                    styles.rowCenter,
                    "mt-4",
                    index % 2 === 0 ? "row" : "row-reverse"
                  )}
                >
                  <div className="col-50">
                    <div>
                      <img
                        width="150px"
                        height="auto"
                        src={textLogo}
                        alt="Mondays Logo"
                      />
                      <h2 className="mt-0">
                        <span>MONDAYS </span>
                        <span className="underline">{plan.name}</span>
                      </h2>
                    </div>
                    <p>{plan.description}</p>
                  </div>
                  <div className="col-50">
                    <div>
                      <img src={plan.image} alt="Mondays Plans" />
                    </div>
                  </div>
                </div>
              </FadeInSection>
            )
          })}
      </section>
    </>
  )
}

export default Recommended
